import { ChevronDownIcon } from "@heroicons/react/outline";
import { useRef, useState } from "react";

const defaultProps = {
  Title: () => <div>Title</div>,
  Content: () => <div>Content</div>,
  ClassName: "",
  ButtonClass: "",
};

export default function Accordian(props) {
  const { Title, Content, ClassName, ButtonClass } = {
    ...defaultProps,
    ...props,
  };

  const [active, setActive] = useState(false);
  const [height, setHeight] = useState("0px");
  const [rotate, setRotate] = useState("transform duration-700 ease");

  const contentSpace = useRef(null);

  function toggleAccordion() {
    setActive(active === false ? true : false);
    // @ts-ignore
    setHeight(active ? "0px" : `${contentSpace.current.scrollHeight}px`);
    setRotate(
      active
        ? "transform duration-700 ease"
        : "transform duration-700 ease rotate-180"
    );
  }
  return (
    <div className={`flex flex-col rounded-lg px-4 text-justify ${ClassName}`}>
      <button
        className={`py-6 box-border appearance-none cursor-pointer focus:outline-none flex space-x-4 items-center flex-auto relative ${ButtonClass}`}
        onClick={toggleAccordion}
      >
        <ChevronDownIcon width={24} className={`${rotate}`} />
        <div
          className={`text-left transform duration-700 ease-in-out ${
            active && "font-medium"
          }`}
        >
          {Title}
        </div>
      </button>
      <div
        ref={contentSpace}
        style={{ maxHeight: `${height}` }}
        className="overflow-auto transition-max-height duration-700 ease-in-out"
      >
        <div className=" pl-8 md:pl-10 pb-10">
          <Content />
        </div>
      </div>
    </div>
  );
}
