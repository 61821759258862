// import PlayIcon2 from "./assets/playIcon2.png";
import PlayIcon1 from "./assets/playIcon1.png";
import HlthDevIcon from "./assets/hlthdev.png";
import Image1 from "./assets/landing1.png";
import Image2 from "./assets/landing2.svg";
import Image3 from "./assets/landing3.svg";
import { BookOpenIcon, DocumentTextIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Formik } from "formik";
import Accordian from "./Accordian/Accordian";

export default function LandingPage() {
  return (
    <>
      <div className="bg-gray-100 pt-28 p-5 md:px-20 relative">
        <div className="grid grid-cols-2 absolute -top-80 md:-top-36 left-0 w-full">
          <div className="h-screen w-1/2 absolute bg-white transform box-border -top-0 skew-y-6 left-0"></div>
          <div className="h-screen w-1/2 absolute bg-white transform box-border -top-0 -skew-y-6 right-0"></div>
        </div>
        {/* navbar */}
        <div className=" h-28 fixed z-10 top-0 left-0 w-full flex justify-between px-4 md:px-20 bg-white items-center">
          <div className="flex items-center space-x-2">
            <img height={40} width={40} src={HlthDevIcon} alt="hlth.ico"></img>
            <b>Onboarding</b>
            <div className="font-light">
              By{" "}
              <a href="asd" target="_blank">
                hlth.dev
              </a>
            </div>
          </div>
          <div>
            <a href="login">Sign In</a>
          </div>
        </div>

        <div className="pt-14 md:pt-60 relative">
          <div className="md:flex justify-center text-3xl md:text-6xl font-medium space-x-2 mb-4 md:mb-8 text-center">
            <div className="">Onboard Users</div>
            <div className="text-primary">Efficiently</div>
          </div>
          <div className=" md:text-2xl text-gray-500 mb-6 text-center">
            Create checklist to onboard patients and employees for your
            hospitals & clinics.
          </div>
          <button className="flex mx-auto p-2 px-8 rounded-md space-x-2 items-center bg-primary mb-8">
            <div className=" text-white text-lg">See Demo</div>
            <img
              width={30}
              height={30}
              className=" text-white"
              src={PlayIcon1}
              alt="playicon"
            ></img>
          </button>
          <img src={Image1} alt="img" className="mx-auto"></img>
        </div>

        <div className="items-center md:grid grid-cols-3 my-20 md:my-40 overflow-auto scrollbar-hide md:gap-x-20">
          <div className="h-full space-y-10 mb-8 md:mb-0">
            {section2Data.map((ele) => {
              return (
                <div className="h-full flex items-start justify-center flex-col space-y-2 md:space-y-4">
                  <div className="text-left text-lg md:text-3xl font-medium">
                    {ele.heading}
                  </div>
                  <div className="text-justify md:text-xl text-gray-500">
                    {ele.description}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="md:col-span-2 flex md:sticky text-center top-0 right-0">
            <img className=" w-full" src={Image2} alt=".."></img>
          </div>
        </div>

        <div className="grid md:grid-cols-3 h-full py-30 gap-20 md:my-36">
          <div className="flex flex-col items-start text-left h-full space-y-4">
            <div className=" text-xl md:text-3xl font-medium">
              We Got Everything Covered
            </div>
            <div className=" text-lg md:text-xl text-gray-500">
              No need to code your own components on writing any logic. Just
              simply use the codebase or interact with our API to quickly get up
              and running.
            </div>
            <button className=" text-lg md:text-xl bg-primary text-white p-2 md:p-4 rounded-md">
              View Documentation
            </button>
          </div>
          <div className="md:col-span-2">
            <div className="grid md:grid-cols-2 gap-4 gap-y-8">
              {section3Data.map((ele) => {
                return (
                  <div className="flex flex-col space-y-4 items-start text-justify p-6 md:p-10 bg-white rounded-md">
                    <ele.icon className="text-primary h-10 md:h-14"></ele.icon>
                    <div className="text-2xl font-medium text-gray-500">
                      Open Source
                    </div>{" "}
                    <div>
                      Loren Ipsum dolor sit amet consectetur adipscing eli, sed
                      do eliusimd tempor incident ut labore er dolore magna
                      aliqua.
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <div className="align-middle grid grid-cols-1 md:grid-cols-3 my-10 gap-20">
          <div className="space-y-10 md:space-y-20 text-left mb-10 md:mb-0">
            <div className=" text-xl md:text-3xl font-medium">
              See Example of Employee Checklist
            </div>
            <div className="flex flex-col space-y-8">
              {[
                "Create and Account",
                "Complete Your Profile",
                "Add License Details",
                "Provide Tax Information",
                "Setup Work Station",
              ].map((ele) => {
                return (
                  <div className="flex gap-x-4">
                    <CheckCircleIcon
                      width={30}
                      className=" text-green-500"
                    ></CheckCircleIcon>
                    <div className="text-lg text-gray-500">{ele}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-span-2">
            <img src={Image3} alt=".." className="w-full" />
          </div>
        </div>

        {/* FAQ Section */}
        <div className="md:py-10 text-center md:text-left">
          <div className=" text-2xl md:text-3xl font-medium mb-4">
            Frequently Asked Question
          </div>
          <div className=" text-lg md:text-xl">
            Answering the questions related to the product.
          </div>

          <div className="my-10 space-y-4">
            {FAQs.map((ele) => {
              return (
                <Accordian
                  Title={ele.que}
                  Content={() => <div>{ele.ans}</div>}
                ></Accordian>
              );
            })}
          </div>
        </div>
      </div>
      {/* footer */}

      <div className="bg-primary text-white p-5 md:p-20 space-y-6">
        <div className=" hidden md:flex flex-col md:flex-row justify-between space-y-6 md:space-y-0">
          <div className="space-y-4 text-left">
            <div className="text-5xl font-medium">Stay Updated</div>
            <div className="text-lg">
              We will send you 1 email every 2 weeks to update you on our
              products and components
            </div>
          </div>
          <div className=" space-y-2 md:space-y-0 md:space-x-2 text-left">
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values) => console.log(values)}
            >
              <>
                <input
                  className="p-4 border border-white bg-primary color-white outline-none"
                  type="email"
                  placeholder="YOUR EMAIL"
                ></input>
                <button className="p-4 uppercase border text-primary bg-white">
                  Subscribe
                </button>
              </>
            </Formik>
          </div>
        </div>

        <div className="md:hidden text-center">
          <div className="text-4xl font-medium">Have a question ?</div>
          <div>We would love to hear about your idea</div>
          <button className=" p-2 px-4 uppercase text- bg-white text-primary my-5">
            Email Us
          </button>
        </div>
        <hr />
        <div className="flex flex-col md:flex-row gap-4  justify-between items-center">
          <img src={HlthDevIcon} alt="ico" height={60} width={60}></img>
          <div className=" md:block space-x-10 flex-1 hidden text-center">
            <a href="asd" className="text-lg">
              For Developers
            </a>
            <a href="asd" className="text-lg">
              For ISV's
            </a>
            <a href="asd" className="text-lg">
              How it Works
            </a>
            <a href="asd" className="text-lg">
              Blog
            </a>
            <a href="asd" className="text-lg">
              FAQ
            </a>
            <a href="asd" className="text-lg">
              Careers
            </a>
          </div>
          <div className="items-center space-x-8 hidden md:flex">
            <div className="h-14 w-14 rounded-full border flex items-center justify-center">
              <i className="fa fa-facebook text-3xl"></i>
            </div>
            <div className="h-14 w-14 rounded-full border flex items-center justify-center">
              <i className="fa fa-twitter text-3xl"></i>
            </div>
            <div className="h-14 w-14 rounded-full border flex items-center justify-center">
              <i className="fa fa-instagram text-3xl"></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const section2Data = [
  {
    heading: "Create Checklists to Onboard Different Users",
    description:
      "Have different onboarding processes for different type of users.",
  },
  {
    heading: "Create Streamline Process For Everyone",
    description:
      "Quicker and simple checklists for the Users to follow through.",
  },
  {
    heading: "Manage & Oversee Employees and Patients",
    description:
      "One application to overlook the time users within your organization.",
  },
];

const section3Data = [
  {
    icon: BookOpenIcon,
    heading: "Open Source",
    description:
      "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
  {
    icon: DocumentTextIcon,
    heading: "Open Source",
    description:
      "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
  {
    icon: BookOpenIcon,
    heading: "Open Source",
    description:
      "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
  {
    icon: DocumentTextIcon,
    heading: "Open Source",
    description:
      "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
];

const FAQs = [
  {
    que: "How can I integrate this with my existing node application",
    ans: "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
  {
    que: "How can I integrate this with my existing node application",
    ans: "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
  {
    que: "How can I integrate this with my existing node application",
    ans: "Loren Ipsum dolor sit amet consectetur adipscing eli, sed do eliusimd tempor incident ut labore er dolore magna aliqua.",
  },
];
